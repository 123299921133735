@charset "UTF-8";
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../images/ajax-loader.gif") center center no-repeat; }

/* Icons */
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
  top: -38px; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      color: #646464; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }

.slick-prev:before, .slick-next:before {
  font-family: "Glyphicons Halflings";
  font-size: 20px;
  line-height: 1;
  color: #bd1f28;
  opacity: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 38%; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: 38%; }
  .slick-prev:before {
    content: ""; }
    [dir="rtl"] .slick-prev:before {
      content: ""; }

.slick-next {
  right: 38%; }
  [dir="rtl"] .slick-next {
    left: 38%;
    right: auto; }
  .slick-next:before {
    content: ""; }
    [dir="rtl"] .slick-next:before {
      content: ""; }

/* Dots */
.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-family: "Glyphicons Halflings";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 1; }

/* Preload images */
body:after {
  content: url(../images/lightbox/close.png) url(../images/lightbox/loading.gif) url(../images/lightbox/prev.png) url(../images/lightbox/next.png);
  display: none; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 11000;
  text-align: center;
  line-height: 0;
  font-weight: normal; }

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  background-color: white;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-container {
  padding: 4px; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/lightbox/loading.gif) no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url(../images/lightbox/prev.png) left 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url(../images/lightbox/next.png) right 48% no-repeat;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  -moz-border-radius-bottomleft: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px;
  color: #ccc; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em; }

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999; }

.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  background: url(../images/lightbox/close.png) top right no-repeat;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

body {
  font-family: "Ubuntu", sans-serif; }

header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999; }
  header .logo {
    position: absolute;
    margin: 0 auto;
    z-index: 1;
    left: 15px;
    height: 85px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s; }
    header .logo img {
      max-height: 100%;
      max-width: 100%;
      display: block;
      margin: 0 auto; }
    @media screen and (min-width: 1200px) {
      header .logo {
        text-align: center;
        left: 45%;
        right: 45%;
        height: 70%;
        top: 15px; } }
    @media screen and (max-width: 1199px) {
      header .logo {
        height: 50px;
        top: 5px; } }
  header nav {
    height: 115px;
    line-height: 115px;
    position: relative;
    background-color: #2f2f2f;
    background-image: url(../images/mesh.png);
    padding: 0 15px; }
    @media screen and (max-width: 1199px) {
      header nav {
        height: 60px;
        line-height: 60px; } }
    header nav button {
      border: 0;
      background-color: transparent;
      display: none;
      z-index: 10; }
      @media screen and (max-width: 1199px) {
        header nav button {
          position: absolute;
          right: 15px;
          height: 60px;
          display: block; } }
      header nav button span {
        width: 20px;
        height: 3px;
        margin-bottom: 3px;
        border-radius: 5px;
        background-color: #fff;
        display: block; }
    header nav .loans-img {
      max-height: 26px; }
    header nav #navContainer {
      background-image: url(../images/mesh.png); }
      header nav #navContainer > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s; }
        header nav #navContainer > ul > li {
          position: relative;
          color: #fff;
          text-transform: uppercase; }
          header nav #navContainer > ul > li a {
            display: block;
            color: #fff;
            text-decoration: none;
            position: relative;
            padding: 0 5px;
            font-weight: normal;
            font-size: 13px;
            letter-spacing: 0.1em;
            -webkit-transition: color 0.5s;
            -moz-transition: color 0.5s;
            transition: color 0.5s; }
            @media (min-width: 1400px) {
              header nav #navContainer > ul > li a {
                padding: 0 8px;
                font-size: 14px; } }
            header nav #navContainer > ul > li a:hover {
              color: #bd1f28;
              text-decoration: none; }
          header nav #navContainer > ul > li ul {
            border-top: 3px solid #bd1f28;
            border-bottom: 3px solid #bd1f28;
            border-radius: 0;
            margin: 0;
            width: 100%;
            background-image: url(../images/mesh.png); }
            @media (min-width: 1200px) {
              header nav #navContainer > ul > li ul {
                width: auto; } }
            header nav #navContainer > ul > li ul li {
              padding: 1em; }
              header nav #navContainer > ul > li ul li:not(:last-child) {
                border-bottom: 2px solid #bd1f28; }
              header nav #navContainer > ul > li ul li a:hover {
                background-color: transparent; }
      @media screen and (min-width: 1200px) {
        header nav #navContainer.collapse {
          display: block;
          height: auto;
          visibility: visible; }
        header nav #navContainer > ul > li {
          float: left; }
          header nav #navContainer > ul > li:not(:first-child) {
            margin-left: 5px; }
            header nav #navContainer > ul > li:not(:first-child):before {
              content: ' ';
              position: absolute;
              top: 50%;
              left: -5px;
              height: 25px;
              width: 2px;
              background-color: #bd1f28;
              margin-top: -13px; }
          header nav #navContainer > ul > li a:after {
            content: ' ';
            width: 0;
            height: 5px;
            background-color: #bd1f28;
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            -webkit-transition: all 0.5s;
            -moz-transition: all 0.5s;
            transition: all 0.5s;
            display: block; }
          header nav #navContainer > ul > li a:hover {
            color: #bd1f28;
            text-decoration: none; }
        header nav #navContainer > ul#secondary-nav {
          float: right; }
          header nav #navContainer > ul#secondary-nav li:last-child:before {
            display: none; } }
      @media screen and (max-width: 1199px) {
        header nav #navContainer {
          text-align: center;
          line-height: 1.5em;
          background-color: #2f2f2f;
          position: absolute;
          left: 0;
          right: 0;
          top: 90px;
          width: 100%;
          margin-top: -45px;
          padding-bottom: 1em; }
          header nav #navContainer > ul > li {
            margin: 0.5em 0; }
            header nav #navContainer > ul > li a {
              padding: 0.5em; } }
      header nav #navContainer .phone {
        font-size: 16px; }
        header nav #navContainer .phone div {
          line-height: 1.5em;
          display: inline-block;
          position: relative;
          top: 10px; }
        header nav #navContainer .phone i {
          opacity: 0.5;
          font-size: 2em;
          top: 8px; }
        header nav #navContainer .phone span {
          display: block; }
      header nav #navContainer .cart-link i {
        font-size: 16px; }
      @media screen and (min-width: 1200px) {
        header nav #navContainer .social {
          display: flex; }
          header nav #navContainer .social a {
            font-size: 16px; } }
      header nav #navContainer .social img {
        width: auto;
        max-height: 20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 3px; }
      header nav #navContainer .social > *:first-child a {
        padding-right: 6px; }
      header nav #navContainer .social > *:last-child a {
        padding-left: 6px; }
      header nav #navContainer .delivery img {
        margin-right: 3px; }
  header .slogan {
    text-align: center;
    font-size: 30px;
    color: #bd1f28;
    font-weight: bold;
    line-height: 45px;
    display: none; }
    @media screen and (min-width: 1200px) {
      header .slogan {
        display: block; } }
    header .slogan span {
      color: #2f2f2f; }
  header.fixed nav {
    height: 80px;
    line-height: 80px; }
    @media screen and (max-width: 1199px) {
      header.fixed nav {
        height: 60px;
        line-height: 60px; } }
  header.fixed .slogan {
    display: none; }

section.content, section#home {
  background-image: url("../images/bg.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  min-height: 560px; }
  @media screen and (min-width: 1440px) {
    section.content, section#home {
      background-size: 100% auto; } }
  section.content.terenowe, section.terenowe#home {
    background-image: url("../images/bg-type-terenowe.jpg"); }
  section.content.ciezarowe, section.ciezarowe#home {
    background-image: url("../images/bg-type-ciezarowe.jpg"); }
  section.content.rolnicze, section.rolnicze#home {
    background-image: url("../images/bg-type-rolnicze.jpg"); }
  section.content.lesne, section.lesne#home {
    background-image: url("../images/bg-type-lesne.jpg"); }
  section.content.przemyslowe, section.przemyslowe#home {
    background-image: url("../images/bg-type-przemyslowe.jpg"); }
  section.content.gasienice, section.gasienice#home {
    background-image: url("../images/bg-type-gasienice.jpg"); }
  section.content.felgi, section.felgi#home {
    background-image: url("../images/bg-type-felgi.jpg"); }

section#content {
  background-color: #fff;
  margin-top: -45px;
  padding-top: 100px; }
  #page-start section#content {
    box-shadow: 0 0 10px 0 #fff;
    margin-top: 0;
    padding-top: 0; }

footer {
  background-image: url("../images/mesh.png");
  background-color: #000;
  position: relative;
  overflow: hidden; }
  footer .container {
    padding-top: 65px; }
  footer .footer-logo {
    line-height: 84px;
    margin-bottom: 30px; }
    @media screen and (max-width: 991px) {
      footer .footer-logo {
        margin-bottom: 15px; } }
    footer .footer-logo .text-right a {
      display: inline-block;
      max-width: 30%; }
      footer .footer-logo .text-right a:nth-child(1n+2) {
        padding-left: 50px; }
      footer .footer-logo .text-right a img {
        max-width: 100%; }
    footer .footer-logo .text-right img {
      max-wdith: 30%; }
  footer .email a {
    color: #fff;
    font-weight: normal;
    font-size: 20px; }
    footer .email a:hover {
      color: #bd1f28;
      text-decoration: none; }
  footer .footer-nav {
    line-height: 20px; }
    footer .footer-nav ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-weight: normal;
      font-size: 14px; }
      @media screen and (max-width: 991px) {
        footer .footer-nav ul {
          font-size: 12px; } }
      footer .footer-nav ul li a {
        display: block;
        color: #fff;
        text-decoration: none;
        position: relative;
        padding: 0 10px;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        transition: all 0.5s; }
        @media screen and (max-width: 1199px) {
          footer .footer-nav ul li a {
            padding: 0 5px; } }
    footer .footer-nav > ul > li {
      margin: 0.5em;
      padding: 0.5em;
      text-align: center;
      position: relative; }
      @media screen and (min-width: 768px) {
        footer .footer-nav > ul > li {
          float: left;
          margin: 0;
          padding: 0; }
          footer .footer-nav > ul > li:not(:first-child) {
            margin-left: 5px; }
            footer .footer-nav > ul > li:not(:first-child):before {
              content: ' ';
              position: absolute;
              top: 50%;
              left: -3px;
              height: 25px;
              width: 2px;
              background-color: #bd1f28;
              margin-top: -13px; }
          footer .footer-nav > ul > li:first-child a {
            padding-left: 0; } }
      footer .footer-nav > ul > li a:hover {
        color: #bd1f28;
        text-decoration: none; }
      footer .footer-nav > ul > li ul {
        background-color: #2f2f2f;
        border-top: 3px solid #bd1f28;
        border-bottom: 3px solid #bd1f28;
        border-radius: 0;
        margin: 0; }
        footer .footer-nav > ul > li ul li {
          padding: 1em; }
          footer .footer-nav > ul > li ul li:not(:last-child) {
            border-bottom: 2px solid #bd1f28; }
          footer .footer-nav > ul > li ul li a:hover {
            background-color: transparent; }
  footer .phone {
    text-align: right;
    font-size: 20px;
    color: #fff; }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      footer .phone {
        font-size: 18px; } }
    footer .phone i {
      color: #999;
      top: 2px; }
    footer .phone span {
      display: inline-block;
      padding: 0 7px 0 10px;
      font-weight: lighter;
      font-style: italic; }
      footer .phone span + span {
        border-left: 1px solid #bd1f28; }
  footer .company-link {
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right; }
    footer .company-link a {
      color: #bd1f28; }
      footer .company-link a:hover {
        text-decoration: none;
        color: #bd1f28; }
  footer #copyright {
    font-size: 10px;
    color: #999;
    margin-top: 40px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    @media screen and (max-width: 767px) {
      footer #copyright {
        text-align: center; } }
    footer #copyright a {
      color: #bd1f28; }
      footer #copyright a:hover {
        color: #999;
        text-decoration: none; }
    footer #copyright .wizjo {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0 40px; }
      @media screen and (min-width: 768px) {
        footer #copyright .wizjo {
          padding: 0;
          text-align: right;
          justify-content: flex-end; } }
      footer #copyright .wizjo a {
        color: #bd1f28;
        margin-left: 5px; }
      footer #copyright .wizjo img {
        vertical-align: top;
        width: 25px; }
  footer .scroll-top {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 50%);
    left: 50%;
    background-color: #bd1f28;
    color: #fff;
    width: 50px;
    height: 50px;
    z-index: 3;
    border-radius: 50%;
    text-align: center;
    font-size: 24px; }
    footer .scroll-top i {
      transform: translateY(-3px);
      display: inline-block; }

/*
 * PAGES
 */
#startCategories {
  /*    @include max-screen($screen-sm-max) {
        display: none;
        height: 0;
    }
*/ }
  @media screen and (min-width: 992px) {
    #startCategories {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      min-height: 68vh; } }
  #startCategories #logoStart {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto; }
    @media screen and (min-width: 992px) {
      #startCategories #logoStart {
        margin-top: 25px; } }
    @media screen and (max-height: 650px) {
      #startCategories #logoStart {
        height: 80px;
        margin-top: 15px; } }
  #startCategories #goDown {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%); }
    #page-main-categories #startCategories #goDown {
      display: none; }
  #startCategories .left {
    display: flex;
    justify-content: space-between;
    left: 0;
    top: 5%; }
    #startCategories .left .loan {
      margin-bottom: 20px; }
  #startCategories .right {
    position: absolute;
    right: 0;
    top: 5%;
    font-weight: bold;
    font-size: 16px;
    color: #bd1f28; }
    #startCategories .right span {
      display: block;
      white-space: nowrap;
      padding-right: 70px;
      line-height: 25px;
      position: relative;
      top: 7px; }
    #startCategories .right .icon {
      float: right;
      background-color: #bd1f28;
      padding: 10px; }
      #startCategories .right .icon i {
        font-size: 30px;
        padding: 5px;
        color: #000;
        opacity: 0.5; }
  @media screen and (max-width: 991px) {
    #startCategories #logoStart,
    #startCategories #goDown,
    #startCategories .left,
    #startCategories .right {
      display: none; } }
  @media screen and (min-width: 992px) {
    #startCategories .categories {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%; } }
  #startCategories .categories .box {
    display: block;
    height: 100%;
    position: relative;
    font-size: 18px;
    color: #bd1f28;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    line-height: 20px; }
    @media screen and (min-width: 992px) {
      #startCategories .categories .box {
        float: left;
        width: 20%;
        padding-top: 52vh; } }
    @media (min-width: 992px) and (max-height: 700px) {
      #startCategories .categories .box {
        padding-top: 54vh; } }
    @media screen and (max-width: 991px) {
      #startCategories .categories .box {
        padding: 1em 0; } }
    #startCategories .categories .box span {
      display: block;
      width: 60px;
      height: 60px;
      background-color: #fff;
      border-radius: 100%;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../images/icons.png");
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s; }
      @media screen and (min-width: 992px) {
        #startCategories .categories .box span {
          position: absolute;
          left: 0;
          right: 0;
          top: 16%; } }
      @media (min-width: 992px) and (max-height: 700px) {
        #startCategories .categories .box span {
          top: 8%; } }
      @media screen and (max-width: 991px) {
        #startCategories .categories .box span {
          margin-bottom: 1em; } }
    @media screen and (min-width: 992px) {
      #startCategories .categories .box:nth-child(1n+2) {
        border-left: 1px solid #ccc; } }
    @media screen and (max-width: 991px) {
      #startCategories .categories .box:nth-child(1n+2) {
        border-bottom: 1px solid #ccc; } }
    #startCategories .categories .box:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      content: ' ';
      z-index: -1;
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      transition: all 0.5s; }
      @media screen and (max-width: 991px) {
        #startCategories .categories .box:before {
          background-position: top; } }
    #startCategories .categories .box:hover:before {
      -webkit-filter: grayscale(0);
      filter: grayscale(0); }
    #startCategories .categories .box:hover span {
      background-color: #bd1f28; }
    #startCategories .categories .box:hover.opony-terenowe span {
      background-position: 0 -60px; }
    #startCategories .categories .box:hover.opony-lesne span {
      background-position: -120px -60px; }
    #startCategories .categories .box:hover.opony-rolnicze span {
      background-position: -240px -60px; }
    #startCategories .categories .box:hover.opony-przemyslowe span {
      background-position: -180px -60px; }
    #startCategories .categories .box:hover.opony-ciezarowe span {
      background-position: -60px -60px; }
    #startCategories .categories .box.opony-terenowe:before {
      background-image: url("../images/start/terenowe.jpg"); }
    #startCategories .categories .box.opony-terenowe span {
      background-position: 0 0; }
    #startCategories .categories .box.opony-lesne:before {
      background-image: url("../images/start/lesne.jpg"); }
    #startCategories .categories .box.opony-lesne span {
      background-position: -120px 0; }
    #startCategories .categories .box.opony-rolnicze:before {
      background-image: url("../images/start/rolnicze.jpg"); }
    #startCategories .categories .box.opony-rolnicze span {
      background-position: -240px 0; }
    #startCategories .categories .box.opony-przemyslowe:before {
      background-image: url("../images/start/przemyslowe.jpg"); }
    #startCategories .categories .box.opony-przemyslowe span {
      background-position: -180px 0; }
    #startCategories .categories .box.opony-ciezarowe:before {
      background-image: url("../images/start/ciezarowe.jpg"); }
    #startCategories .categories .box.opony-ciezarowe span {
      background-position: -60px 0; }

section#promo {
  background-color: #fff;
  padding-bottom: 150px; }

section#home {
  background-image: url("../images/start.jpg");
  padding-top: 120px;
  text-align: justify;
  font-size: 14px;
  font-weight: lighter; }
  @media screen and (max-width: 991px) {
    section#home {
      background-position: left top; } }
  section#home strong {
    font-weight: 500; }
  section#home p {
    line-height: 22px; }
  section#home h2 {
    color: #646464;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: lighter;
    margin-bottom: 45px; }
  section#home .news-item-wrap {
    max-width: 226px; }
  section#home .news-item {
    display: block;
    position: relative;
    margin: 0 0 30px; }
    section#home .news-item:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: .7;
      background-color: #000;
      transition: all .2s ease-in-out; }
    section#home .news-item img {
      max-width: 100%;
      height: auto; }
    section#home .news-item:hover:before {
      background-color: #bd1f28; }
  section#home .news-headline {
    text-align: left;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 3;
    color: #fff;
    font-size: 14px;
    padding: 20px 15px;
    font-weight: bold; }
    @media (min-width: 480px) {
      section#home .news-headline {
        font-size: 16px; } }
    section#home .news-headline img {
      display: block;
      max-width: 100%;
      height: auto; }
    section#home .news-headline time {
      display: block;
      font-weight: bold;
      font-size: 12px;
      margin-bottom: 10px; }

section#news time {
  display: block;
  color: #bd1f28;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 0.5em; }

section#news aside h3 {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 18px;
  color: #2d2d2d;
  font-weight: lighter;
  margin-bottom: 30px;
  margin-top: 0; }

section#news aside .news-side-item {
  margin-bottom: 25px; }
  section#news aside .news-side-item a {
    display: block;
    color: #646464;
    font-size: 16px; }
    section#news aside .news-side-item a:hover {
      color: #646464;
      text-decoration: none; }
    section#news aside .news-side-item a .more {
      display: block;
      position: relative;
      background-image: url("../images/more.png");
      background-repeat: no-repeat;
      background-position: left center;
      height: 18px;
      z-index: 1;
      margin: 10px 0; }
      section#news aside .news-side-item a .more:after {
        position: absolute;
        display: block;
        height: 1px;
        top: 9px;
        left: 18px;
        right: 0;
        background-color: #b2b2b2;
        content: ' ';
        z-index: 0; }

section#news aside .pagination {
  display: block;
  margin: 0;
  padding: 0;
  border-radius: 0; }
  section#news aside .pagination ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    section#news aside .pagination ul li {
      float: left;
      margin: 0 15px 15px 0; }
      section#news aside .pagination ul li a {
        text-align: center;
        line-height: 21px;
        display: block;
        width: 25px;
        height: 25px;
        font-weight: bold;
        font-size: 16px;
        border: 2px solid #bd1f28;
        color: #646464;
        margin: 0;
        padding: 0;
        background-image: none; }
        section#news aside .pagination ul li a.active, section#news aside .pagination ul li a:hover, section#news aside .pagination ul li a:focus {
          text-decoration: none; }
        section#news aside .pagination ul li a.active, section#news aside .pagination ul li a:hover {
          color: #fff;
          background-color: #bd1f28; }

section#news article {
  padding-left: 30px;
  padding-bottom: 1em;
  border-left: 1px solid #b2b2b2;
  font-size: 14px;
  font-weight: lighter;
  text-align: justify; }
  section#news article h1 {
    font-size: 36px;
    font-weight: lighter;
    margin-top: 0; }

section#news .slider {
  margin: 1em 0; }
  section#news .slider img {
    border-left: 70px solid #bd1f28;
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0; }

section#tireIndex aside {
  padding-left: 30px;
  background-image: url("../images/index-size.png");
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 150px; }
  @media screen and (min-width: 992px) {
    section#tireIndex aside {
      margin-top: -36px; } }
  section#tireIndex aside ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
    section#tireIndex aside ul li {
      margin: 0;
      padding: 0;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      margin: 5px 0; }
      @media screen and (max-width: 767px) {
        section#tireIndex aside ul li {
          margin: 10px 0;
          padding: 5px; } }
      section#tireIndex aside ul li:not(:last-child) {
        border-bottom: 1px solid #b2b2b2; }
      section#tireIndex aside ul li a {
        color: #bd1f28;
        display: block; }
        section#tireIndex aside ul li a:hover, section#tireIndex aside ul li a:focus {
          text-decoration: none; }
        section#tireIndex aside ul li a:hover {
          color: #646464; }

section#tireIndex table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 40px; }
  section#tireIndex table tr th {
    color: #4f4f4f;
    font-size: 20px; }
    section#tireIndex table tr th:first-child {
      text-align: right;
      padding-right: 15px; }
    section#tireIndex table tr th:last-child {
      padding-left: 15px; }
  section#tireIndex table tr td.size-big {
    vertical-align: top;
    font-size: 60px;
    font-weight: bold;
    text-align: right;
    padding-right: 15px;
    border-right: 2px solid #b2b2b2;
    color: #4f4f4f; }
  section#tireIndex table tr td.size-list {
    padding-left: 15px;
    vertical-align: middle; }
    section#tireIndex table tr td.size-list a {
      font-weight: bold;
      color: #bd1f28;
      margin: 5px 30px 5px 0;
      box-sizing: border-box;
      vertical-align: top; }
      @media screen and (min-width: 992px) {
        section#tireIndex table tr td.size-list a {
          display: inline-block;
          width: 20%; } }
      @media screen and (max-width: 991px) {
        section#tireIndex table tr td.size-list a {
          display: inline-block;
          padding: 5px 0;
          width: 40%; } }
      @media screen and (max-width: 767px) {
        section#tireIndex table tr td.size-list a {
          width: 100%; } }
      section#tireIndex table tr td.size-list a:hover {
        color: #646464;
        text-decoration: none; }

section#tireIndex h1 {
  font-weight: lighter;
  font-size: 36px;
  margin: 0;
  text-align: center;
  color: #4f4f4f; }

section#tires .product-list-item {
  margin: 1px; }
  @media screen and (min-width: 610px) {
    section#tires .product-list-item {
      float: left;
      width: calc(50% - 2px); } }
  @media screen and (min-width: 992px) {
    section#tires .product-list-item {
      width: calc(33% - 2px); } }
  @media screen and (min-width: 1200px) {
    section#tires .product-list-item {
      width: calc(25% - 2px); } }

section#tire .product-image {
  background-color: #fff;
  padding: 35px;
  margin-bottom: 20px; }
  section#tire .product-image img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto; }

section#tire .product-extras > div {
  text-align: left;
  margin-bottom: 20px;
  font-size: 14px;
  line-height: 1em;
  height: 60px;
  padding-top: 15px; }
  @media screen and (min-width: 992px) {
    section#tire .product-extras > div {
      float: left;
      width: 50%; } }
  @media screen and (max-width: 1199px) {
    section#tire .product-extras > div {
      padding-left: 10px;
      padding-right: 10px; } }
  section#tire .product-extras > div.type {
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 65px; }
    section#tire .product-extras > div.type-terenowe {
      background-image: url("../images/type-terenowe.png"); }
    section#tire .product-extras > div.type-ciezarowe {
      background-image: url("../images/type-ciezarowe.png"); }
    section#tire .product-extras > div.type-rolnicze {
      background-image: url("../images/type-rolnicze.png"); }
    section#tire .product-extras > div.type-lesne {
      background-image: url("../images/type-lesne.png"); }
    section#tire .product-extras > div.type-przemyslowe {
      background-image: url("../images/type-przemyslowe.png"); }
  section#tire .product-extras > div.warranty span {
    float: left;
    height: 100%;
    color: #bd1f28;
    font-size: 2.2em;
    line-height: 1em;
    font-weight: bold;
    margin-right: 3px; }

section#tire h1 {
  font-size: 36px;
  font-weight: lighter;
  margin-top: 0; }

section#tire h4 {
  font-size: 18px;
  margin-bottom: 15px; }

section#tire .product-attributes {
  width: 100%;
  font-size: 14px; }
  section#tire .product-attributes tr {
    border-bottom: 1px solid #a1a1a1; }
    section#tire .product-attributes tr td {
      width: 50%;
      padding: 3px 0; }
      section#tire .product-attributes tr td:first-child {
        font-weight: bolder; }

section#tire .product-benefits img {
  border-radius: 100%;
  border: 3px solid #fff;
  margin-right: 15px;
  margin-bottom: 15px;
  float: left;
  width: 65px;
  height: 65px; }
  section#tire .product-benefits img:hover {
    border-color: #bd1f28; }

section#tire .product-rating {
  color: #bd1f28;
  font-size: 16px; }

section#tire .add-to-cart {
  margin: 20px 0;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  line-height: 1.3; }
  @media (min-width: 768px) {
    section#tire .add-to-cart {
      display: inline-block; } }
  @media (min-width: 768px) {
    section#tire .add-to-cart .cart {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      margin: 0 -8px; } }
  @media (max-width: 767px) {
    section#tire .add-to-cart .cart > *:not(:last-child) {
      margin: 0 0 20px; } }
  @media (min-width: 768px) {
    section#tire .add-to-cart .cart > * {
      padding: 0 8px;
      margin: 0; } }
  section#tire .add-to-cart .price-wrap .promo-label {
    color: #bd1f28;
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin: 0 0 20px; }
  section#tire .add-to-cart .price-wrap .regular-price {
    display: block;
    color: #4c4c4c;
    font-size: 18px;
    text-decoration: line-through;
    margin: 0 0 5px;
    font-weight: 500; }
  section#tire .add-to-cart ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
    section#tire .add-to-cart ul li {
      background-color: #c5c5c5;
      display: block;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase; }
      section#tire .add-to-cart ul li.active {
        background-color: #fff; }
        section#tire .add-to-cart ul li.active a {
          color: #bd1f28; }
          section#tire .add-to-cart ul li.active a img {
            opacity: 1;
            -webkit-filter: grayscale(0);
            filter: grayscale(0); }
      section#tire .add-to-cart ul li a {
        display: block;
        color: #000;
        text-decoration: none; }
        section#tire .add-to-cart ul li a img {
          float: left;
          margin-top: 0.5em;
          margin-right: 5px;
          opacity: 0.5;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
  section#tire .add-to-cart > div > div {
    display: none; }
    section#tire .add-to-cart > div > div.active {
      display: block; }
  section#tire .add-to-cart form {
    padding: 30px 20px; }
    section#tire .add-to-cart form .price {
      font-size: 30px;
      color: #bd1f28;
      font-weight: normal;
      vertical-align: middle; }
    section#tire .add-to-cart form .quantity {
      vertical-align: middle;
      display: inline-flex;
      align-items: center; }
      section#tire .add-to-cart form .quantity input {
        border-radius: 0;
        width: 2.4em;
        font-size: 18px;
        font-weight: bolder;
        height: 35px;
        line-height: 1em;
        margin-top: 5px;
        text-align: center;
        padding: 6px 0; }
        section#tire .add-to-cart form .quantity input::-webkit-inner-spin-button, section#tire .add-to-cart form .quantity input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        section#tire .add-to-cart form .quantity input:focus {
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(189, 31, 40, 0.6);
          border-color: #bd1f28; }
      section#tire .add-to-cart form .quantity .quantity-btns .quantity-btn {
        color: #bd1f28;
        background-color: #fff;
        display: block;
        line-height: 1;
        cursor: pointer;
        padding: 6px 6px; }
        section#tire .add-to-cart form .quantity .quantity-btns .quantity-btn:first-child {
          padding-bottom: 0; }
        section#tire .add-to-cart form .quantity .quantity-btns .quantity-btn:last-child {
          padding-bottom: 0; }
        section#tire .add-to-cart form .quantity .quantity-btns .quantity-btn span {
          transition: all .2s ease-out; }
        section#tire .add-to-cart form .quantity .quantity-btns .quantity-btn:hover span {
          color: #4f4f4f; }
    section#tire .add-to-cart form button {
      border: 0;
      background-color: #bd1f28;
      color: #fff;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 25px;
      padding: 5px 10px; }
      section#tire .add-to-cart form button i {
        top: 3px;
        font-weight: normal;
        font-size: 16px;
        margin-right: 5px; }

section#tire .express-delivery {
  margin-bottom: 20px; }

section#tire .product-desc {
  font-size: 14px;
  font-weight: lighter; }

/*
 * GLOBAL
 */
.promo-container .slick-track {
  padding-top: 20px; }

.product-list-item {
  padding: 30px;
  text-align: center;
  position: relative;
  cursor: pointer;
  background-color: #fff;
  z-index: 1;
  color: #646464;
  text-decoration: none;
  display: block;
  margin-bottom: 1em; }
  .product-list-item span {
    display: block; }
  .product-list-item .badge {
    position: absolute;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    background-color: #bd1f28;
    color: #fff;
    padding: 12px 30px;
    height: auto;
    z-index: 2;
    text-transform: uppercase;
    opacity: 0;
    transition: all .3s ease-out; }
  .product-list-item .product-name,
  .product-list-item .price {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px; }
  .product-list-item .product-name {
    width: 60%;
    height: 5.25em; }
  .product-list-item .price {
    display: inline-block;
    font-size: 0; }
    .product-list-item .price span {
      display: inline-block; }
    .product-list-item .price del {
      display: block;
      color: #2b2b2b;
      max-height: 0;
      overflow: hidden;
      transition: all .3s ease-out;
      font-size: 14px; }
      .product-list-item .price del span {
        text-decoration: line-through; }
    .product-list-item .price ins {
      color: #fff;
      display: inline-block;
      width: auto;
      padding: 5px 0 5px 10px;
      text-transform: uppercase;
      background-color: #bd1f28;
      font-size: 14px; }
    .product-list-item .price small {
      display: inline-block;
      color: #fff;
      padding: 5px 10px 5px 5px;
      background-color: #bd1f28;
      font-size: 14px; }
  .product-list-item .image {
    height: 200px;
    margin-bottom: 20px;
    position: relative;
    display: inline-block; }
    .product-list-item .image:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      opacity: 0;
      content: ' ';
      z-index: 0; }
    .product-list-item .image img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      max-height: 100%; }
  .product-list-item:before {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ' ';
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.1); }
  .product-list-item:hover, .product-list-item:focus {
    color: #646464;
    text-decoration: none; }
    .product-list-item:hover:before, .product-list-item:focus:before {
      opacity: 1; }
    .product-list-item:hover .badge, .product-list-item:focus .badge {
      opacity: 1; }
    .product-list-item:hover .image:after, .product-list-item:focus .image:after {
      opacity: 1; }
    .product-list-item:hover .icon span, .product-list-item:focus .icon span {
      background-color: #bd1f28; }
  .product-list-item .icon {
    position: relative;
    width: 100%;
    height: 60px;
    text-align: center;
    margin: 20px auto; }
    .product-list-item .icon:before {
      position: absolute;
      content: ' ';
      top: 30px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      z-index: -1;
      background-color: #b2b2b2; }
    .product-list-item .icon span {
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../images/icons.png");
      display: inline-block;
      width: 60px;
      height: 60px;
      background-color: #7f7f7f;
      border-radius: 100%; }
  .product-list-item:not(.promo-product) .woocommerce-Price-amount {
    font-size: 14px;
    background-color: #bd1f28;
    padding: 5px 0 5px 10px;
    color: #fff; }
  .product-list-item.promo-product .price del {
    max-height: 0; }
  .product-list-item.promo-product:hover .price del, .product-list-item.promo-product:focus .price del {
    max-height: 50px;
    padding: 8px; }
  .product-list-item.type-terenowe .icon span {
    background-position: 0 -60px; }
  .product-list-item.type-ciezarowe .icon span {
    background-position: -60px -60px; }
  .product-list-item.type-lesne .icon span {
    background-position: -120px -60px; }
  .product-list-item.type-przemyslowe .icon span {
    background-position: -180px -60px; }
  .product-list-item.type-rolnicze .icon span {
    background-position: -240px -60px; }
  .product-list-item.type-unknown .icon span {
    background-position: 60px -60px; }
  .product-list-item .image:after,
  .product-list-item .icon span, .product-list-item:before {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s; }

.marquee {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  height: 180px; }
  .marquee .marquee-cont {
    position: absolute;
    white-space: nowrap;
    left: 0;
    margin: 70px 0; }
    .marquee .marquee-cont > div {
      width: auto;
      padding: 0 30px;
      display: inline; }
      .marquee .marquee-cont > div img {
        max-height: 40px; }

.goTop {
  display: none;
  position: fixed;
  bottom: 3em;
  right: 3em;
  width: 3em;
  height: 3em;
  line-height: 3em;
  color: #fff;
  text-align: center;
  background-color: #bd1f28;
  border-radius: 0.5em; }
  .goTop:hover, .goTop:focus {
    color: #fff;
    text-decoration: none; }

.nopad-l {
  padding-left: 0; }

.nopad-r {
  padding-right: 0; }

.breadcrumbs ul {
  list-style-type: none;
  margin: 0 0 65px 0;
  padding: 0;
  text-align: center; }
  .breadcrumbs ul li {
    display: inline-block;
    font-size: 12px;
    position: relative; }
    .breadcrumbs ul li a {
      color: #646464; }
      .breadcrumbs ul li a:hover {
        color: #bd1f28;
        text-decoration: none; }
    .breadcrumbs ul li + li:before {
      color: #bd1f28;
      content: '/';
      padding: 0 10px; }

.tooltip.bottom {
  margin-top: -5px; }

.tooltip.in {
  opacity: 1; }

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fff;
  border-width: 0 24px 11px;
  margin-left: -24px; }

.tooltip .tooltip-inner {
  background-color: #fff;
  box-shadow: 0 0 5px rgba(100, 100, 100, 0.5);
  color: #000;
  border-radius: 0;
  font-weight: bolder;
  font-size: 12px;
  padding: 10px 40px;
  text-transform: uppercase; }

img.alignnone {
  max-width: 100%;
  height: auto; }

.promo-container h2 {
  text-align: center;
  color: #646464;
  font-size: 17px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: lighter;
  margin-bottom: 30px; }

a:focus {
  outline: none; }

.row--flex {
  display: flex;
  flex-wrap: wrap; }

@media (min-width: 992px) {
  .contact-wrap {
    padding: 60px 0;
    display: flex; } }

.contact-wrap .contact-content {
  background-color: #333333;
  color: #fff;
  padding: 40px 30px;
  font-size: 16px; }
  @media (min-width: 480px) {
    .contact-wrap .contact-content {
      font-size: 18px; } }
  @media (min-width: 992px) {
    .contact-wrap .contact-content {
      width: 33.33%; } }
  .contact-wrap .contact-content .contact-title {
    margin: 0 0 35px; }
  .contact-wrap .contact-content a {
    color: #bd1f28;
    font-weight: bold; }
  .contact-wrap .contact-content p {
    margin-bottom: 25px; }

@media (min-width: 992px) {
  .contact-wrap .contact-map {
    width: 66.66%; } }

.contact-wrap .contact-map iframe {
  width: 100%;
  height: 100%;
  min-height: 400px; }

@media (min-width: 768px) {
  .extra-categories {
    display: flex; } }

.extra-categories .extra-category {
  position: relative;
  display: block;
  overflow: hidden; }
  @media (min-width: 768px) {
    .extra-categories .extra-category {
      width: 50%; } }
  .extra-categories .extra-category .extra-category__img {
    transition: all .3s ease-out; }
  .extra-categories .extra-category .extra-category__title {
    position: absolute;
    z-index: 2;
    bottom: 6%;
    left: 10%;
    color: #fff;
    letter-spacing: .2em;
    text-transform: uppercase;
    font-size: 5vw; }
    @media (min-width: 480px) {
      .extra-categories .extra-category .extra-category__title {
        font-size: 4vw; } }
    @media (min-width: 768px) {
      .extra-categories .extra-category .extra-category__title {
        font-size: 3vw; } }
    @media (min-width: 992px) {
      .extra-categories .extra-category .extra-category__title {
        font-size: 30px; } }
  .extra-categories .extra-category:hover .extra-category__img {
    transform: scale(1.05); }

.opinions {
  background-image: url(../images/redbg.jpg);
  padding: 70px 0 40px; }
  .opinions .opinions__heading-title {
    text-align: center;
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
    letter-spacing: .2em;
    margin: 0 0 50px; }
  .opinions .opinion {
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 70px 30px;
    margin-bottom: 75px;
    position: relative; }
  .opinions .opinion__footer {
    background-color: #000;
    text-align: center;
    transform: translateY(50%);
    position: absolute;
    bottom: 0;
    width: calc(100% - 60px);
    left: 30px;
    padding: 15px;
    font-weight: 500; }
    .opinions .opinion__footer:before {
      content: "";
      position: absolute;
      bottom: calc(100% - 1px);
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-bottom: 10px solid black; }
    .opinions .opinion__footer .opinion__author {
      font-style: italic;
      display: block;
      margin: 0 0 5px; }
    .opinions .opinion__footer .opinion__fate {
      font-size: 14px; }

.stats {
  background-image: url(../images/mesh.png);
  padding: 50px 0;
  overflow: hidden; }
  .stats .stat {
    position: relative;
    margin: 0 0 45px; }
    .stats .stat .stat__bg {
      position: relative;
      z-index: 2; }
      .stats .stat .stat__bg img {
        margin: 0 auto; }
    .stats .stat .stat__text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      z-index: 3; }
    .stats .stat .stat__value {
      color: #bd1f28;
      display: block;
      font-size: 32px;
      font-weight: bold; }
      @media (min-width: 480px) {
        .stats .stat .stat__value {
          font-size: 36px; } }
      @media (min-width: 768px) {
        .stats .stat .stat__value {
          font-size: 30px; } }
      @media (min-width: 992px) {
        .stats .stat .stat__value {
          font-size: 46px; } }
    .stats .stat .stat__caption {
      color: #fff;
      line-height: 1.2; }
      @media (min-width: 480px) {
        .stats .stat .stat__caption {
          font-size: 12px; } }
      @media (min-width: 768px) {
        .stats .stat .stat__caption {
          font-size: 16px; } }
      @media (min-width: 992px) {
        .stats .stat .stat__caption {
          font-size: 18px; } }
    .stats .stat .stat__shadow {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 50%);
      mix-blend-mode: multiply;
      z-index: 1; }
  .stats .odometer-formatting-mark {
    display: none; }
  .stats .stats__heading {
    text-align: center;
    padding: 10px 0 0; }
  .stats .stats__heading-title {
    text-transform: uppercase;
    color: #bd1f28;
    font-size: 16px;
    margin: 0 0 20px; }
    @media (min-width: 480px) {
      .stats .stats__heading-title {
        font-size: 18px; } }
  .stats .stats__heading-subtitle {
    text-transform: uppercase;
    color: #fff;
    font-size: 24px;
    margin: 0 0 20px;
    letter-spacing: .2em; }
    @media (min-width: 480px) {
      .stats .stats__heading-subtitle {
        font-size: 30px; } }
    @media (min-width: 768px) {
      .stats .stats__heading-subtitle {
        font-size: 50px; } }

.woocommerce #respond input#submit, .woocommerce #respond input#submit:disabled[disabled], .woocommerce #respond input#submit.alt,
.woocommerce a.button,
.woocommerce a.button:disabled[disabled],
.woocommerce a.button.alt,
.woocommerce button.button,
.woocommerce button.button:disabled[disabled],
.woocommerce button.button.alt,
.woocommerce input.button,
.woocommerce input.button:disabled[disabled],
.woocommerce input.button.alt {
  color: #fff;
  background-color: #bd1f28;
  display: inline-block;
  width: auto;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 0;
  font-size: 14px; }
  .woocommerce #respond input#submit:hover, .woocommerce #respond input#submit:disabled[disabled]:hover, .woocommerce #respond input#submit.alt:hover,
  .woocommerce a.button:hover,
  .woocommerce a.button:disabled[disabled]:hover,
  .woocommerce a.button.alt:hover,
  .woocommerce button.button:hover,
  .woocommerce button.button:disabled[disabled]:hover,
  .woocommerce button.button.alt:hover,
  .woocommerce input.button:hover,
  .woocommerce input.button:disabled[disabled]:hover,
  .woocommerce input.button.alt:hover {
    background-color: #bd1f28;
    color: #fff; }

.woocommerce-placeholder {
  padding: 10px;
  background-color: #fff; }

.cart_totals table {
  margin-bottom: 10px; }
  .cart_totals table td {
    padding: 3px 10px; }

.woocommerce table.shop_table,
.woocommerce-checkout #payment,
.woocommerce-checkout #payment div.payment_box {
  border-radius: 0; }

#billing_country_field {
  display: none; }

.woocommerce form .form-row input.input-text,
.woocommerce form .form-row textarea {
  border: 1px solid #646464;
  padding: 10px 15px;
  background-color: transparent; }

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: #646464; }

.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
  border-color: #bd1f28; }

.woocommerce .radio input[type="radio"], .woocommerce .radio-inline input[type="radio"], .woocommerce .checkbox input[type="checkbox"], .woocommerce .checkbox-inline input[type="checkbox"] {
  position: static; }
