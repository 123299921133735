$imgPath: '../../images';
$font: 'Ubuntu', sans-serif;

$lead: #bd1f28;
$text: #646464;
$textAlt: #4f4f4f;
$link: $lead;
$linkAlt: $text;

$borderColor: #2d2d2d;
$typeIconLine: #b2b2b2;

$headerText: $text;

$asideHeader: $borderColor;
$asideLine: $typeIconLine;

$startBorder: #ccc; //;rgba(#000, 0.2);
$startAnimationTime: 0.5s;

$iconPositionTerenowe: 0;
$iconPositionCiezarowe: -60px;
$iconPositionLesne: -120px;
$iconPositionPrzemyslowe: -180px;
$iconPositionRolnicze: -240px;
$iconPositionUnknown: 60px;
$iconBackground: #7f7f7f;

$navBg: #2f2f2f;
$navText: #fff;
$navLink: $navText;
$navLinkHover: $lead;
$navLinkLine: $lead;
$sloganText: #2f2f2f;

$productBorder: #a1a1a1;
$productCartBorder: #dbdbdb;
$productTabDisabled: #c5c5c5;

$footerLinkLine: $lead;
$footerLink: #fff;
$footerLight: #999;
$footerText: #fff;

$copyrightColor: $footerLight;

$productHover: #e5e5e5;
$listItemHover: rgba(#000, 0.1);

$slick-arrow-color: $lead;