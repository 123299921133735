body {
    font-family: $font;
}
header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;

    #page-start &,
    #page-main-categories & {
        @include min-screen($screen-md-min) {

        }
    }

    .logo {
        position: absolute;
        //width: 100%;
        margin: 0 auto;
        z-index: 1;
        left: 15px;
        height: 85px;
        @include transition(all 0.3s);

        img {
            max-height: 100%;
            max-width: 100%;
            display: block;
            margin: 0 auto;
        }

        @include min-screen($screen-lg-min) {
            text-align: center;
            left: 45%;
            right: 45%;
            height: 70%;
            top: 15px;
        }


        @include max-screen($screen-md-max) {
            height: 50px;
            top: 5px;
        }
    }

    nav {
        height: 115px;
        line-height: 115px;
        position: relative;
        background-color: $navBg;
        background-image: url(../images/mesh.png);
        padding: 0 15px;
        //float: left;
        //width: 100%;

        @include max-screen($screen-md-max) {
            height: 60px;
            line-height: 60px;
        }

        button {
            border: 0;
            background-color: transparent;
            display: none;
            z-index: 10;

            @include max-screen($screen-md-max) {
                position: absolute;
                right: 15px;
                height: 60px;
                display: block;
            }

            span {
                width: 20px;
                height: 3px;
                margin-bottom: 3px;
                border-radius: 5px;
                background-color: $navLink;
                display: block;
            }
        }

        .loans-img{
            max-height: 26px;
        }

        #navContainer {
            background-image: url(../images/mesh.png);

            > ul {
                list-style-type: none;
                margin: 0;
                padding: 0;
                @include transition(all 0.5s);

                > li {
                    position: relative;
                    color: $navLink;
                    text-transform: uppercase;

                    a {
                        display: block;
                        color: $navLink;
                        text-decoration: none;
                        position: relative;
                        padding: 0 5px;
                        font-weight: normal;
                        font-size: 13px;
                        letter-spacing: 0.1em;
                        @include transition(color 0.5s);

                        @media (min-width: 1400px){
                            padding: 0 8px;
                            font-size: 14px;
                        }

                        &:hover {
                            //color: $navLink;
                            color: $navLinkHover;
                            text-decoration: none;

                            &:after {
                                //width: 100%;
                            }
                        }
                    }

                    ul {
                        border-top: 3px solid $lead;
                        border-bottom: 3px solid $lead;
                        border-radius: 0;
                        margin: 0;
                        width: 100%;
                        background-image: url(../images/mesh.png);

                        @media (min-width: $screen-lg-min){
                            width: auto;
                        }

                        li {
                            padding: 1em;

                            &:not(:last-child){
                                border-bottom: 2px solid $lead;
                            }

                            a {
                                &:hover {
                                    background-color: transparent;
                                }
                            }

                        }
                    }
                }
            }

            @include min-screen($screen-lg-min) {
                &.collapse {
                    display: block;
                    height: auto;
                    visibility: visible;
                }

                > ul {
                    > li {
                        float: left;

                        &:not(:first-child) {
                            margin-left:5px;

                            &:before {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                left: -5px;
                                height: 25px;
                                width: 2px;
                                background-color: $navLinkLine;
                                margin-top: -13px;
                            }
                        }

                        a {
                            &:after {
                                content: ' ';
                                width: 0;
                                height: 5px;
                                background-color: $navLinkLine;
                                position: absolute;
                                bottom: 40px;
                                left: 0;
                                right: 0;
                                margin: 0 auto;
                                @include transition(all 0.5s);
                                display: block;
                            }

                            &:hover {
                                //color: $navLink;
                                color: $navLinkHover;
                                text-decoration: none;

                                &:after {
                                    //width: 100%;
                                }
                            }
                        }
                    }

                    &#secondary-nav {
                        float: right;

                        li {
                            &:last-child {
                                &:before {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            @include max-screen($screen-md-max) {
                text-align: center;
                line-height: 1.5em;
                background-color: $navBg;
                position: absolute;
                left: 0;
                right: 0;
                top: 90px;
                width: 100%;
                margin-top: -45px;
                padding-bottom: 1em;

                > ul {
                    > li {
                        margin: 0.5em 0;

                        a {
                            padding: 0.5em;
                        }
                    }
                }
            }

            .phone {
                font-size: 16px;

                div {
                    line-height: 1.5em;
                    display: inline-block;
                    position: relative;
                    top: 10px;

                }

                i {
                    opacity: 0.5;
                    font-size: 2em;
                    top: 8px;
                }

                span {
                    display: block;
                }
            }

            .cart-link{
                i{
                    font-size: 16px;
                }
            }

            .social{
                @include min-screen($screen-lg-min) {
                    display: flex;

                    a{
                        font-size: 16px;
                    }
                }

                img{
                    width: auto;
                    max-height: 20px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 3px;
                }

                > *{
                    &:first-child{
                        a{
                           padding-right: 6px;
                        }
                    }
                    &:last-child{
                        a{
                           padding-left: 6px;
                        }
                    }
                }
            }

            .delivery {
                img {
                    margin-right: 3px;
                }
            }
        }
    }

    .slogan {
        text-align: center;
        font-size: 30px;
        color: $lead;
        font-weight: bold;
        line-height: 45px;
        display: none;

        @include min-screen($screen-lg-min) {
            display: block;
        }

        span {
            color: $sloganText;
        }
    }

    &.fixed{
        nav{
            height: 80px;
            line-height: 80px;

            @include max-screen($screen-md-max) {
                height: 60px;
                line-height: 60px;
            }
        }
        .slogan{
            display: none;
        }
    }
}
section {
    &.content {
        background-image: url('../images/bg.jpg');
        background-position: center top;
        background-repeat: no-repeat;
        min-height: 560px;
        @include min-screen(1440px) {
            background-size: 100% auto;
        }

        @each $type in terenowe, ciezarowe, rolnicze, lesne, przemyslowe, gasienice, felgi {
            &.#{$type} {
                background-image: url('../images/bg-type-#{$type}.jpg');
            }
        }
        //background-size: 100% auto;
    }

    //main content box
    &#content {
        background-color: #fff;
        margin-top: -45px;
        padding-top: 100px;

        #page-start & {
            box-shadow: 0 0 10px 0 #fff;
            margin-top: 0;
            padding-top: 0;
        }
    }
}
footer {
    background-image: url('../images/mesh.png');
    background-color: #000;
    position: relative;
    overflow: hidden;

    .container {
        padding-top: 65px;
    }
    .footer-logo {
        line-height: 84px;
        margin-bottom: 30px;

        @include max-screen($screen-sm-max) {
            margin-bottom: 15px;
        }

        .text-right {
            a {
                display: inline-block;
                &:nth-child(1n+2) {
                    padding-left: 50px;
                }
                max-width: 30%;

                img {
                    max-width: 100%;
                }
            }

            img {
                max-wdith: 30%;
            }
        }
    }

    .email {
        a {
            color: $footerLink;
            font-weight: normal;
            font-size: 20px;

            &:hover {
                color: $lead;
                text-decoration: none;
            }
        }
    }

    .footer-nav {
        line-height: 20px;

        ul{
            list-style-type: none;
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 14px;
            @include max-screen($screen-sm-max) {
                font-size: 12px;
            }

            li{
                a{
                    display: block;
                    color: $footerLink;
                    text-decoration: none;
                    position: relative;
                    padding: 0 10px;

                    @include max-screen($screen-md-max) {
                        padding: 0 5px;
                    }

                    @include transition(all 0.5s);
                }
            }
        }

        > ul {
            > li {
                margin: 0.5em;
                padding: 0.5em;
                text-align: center;
                @include min-screen($screen-sm-min) {
                    float: left;
                    margin: 0;
                    padding: 0;

                    &:not(:first-child) {
                        margin-left:5px;

                        &:before {
                            content: ' ';
                            position: absolute;
                            top: 50%;
                            left: -3px;
                            height: 25px;
                            width: 2px;
                            background-color: $footerLinkLine;
                            margin-top: -13px;
                        }
                    }

                    &:first-child {
                        a {
                            padding-left: 0;
                        }
                    }
                }

                position: relative;

                a {


                    &:hover {
                        //color: $navLink;
                        color: $navLinkHover;
                        text-decoration: none;
                    }
                }

                ul {
                    background-color: $navBg;
                    border-top: 3px solid $lead;
                    border-bottom: 3px solid $lead;
                    border-radius: 0;
                    margin: 0;

                    li {
                        padding: 1em;

                        &:not(:last-child){
                            border-bottom: 2px solid $lead;
                        }

                        a {
                            &:hover {
                                background-color: transparent;
                            }
                        }


                    }
                }
            }
        }
    }

    .phone {
        text-align: right;
        font-size: 20px;
        color: $footerText;
        @include screen($screen-sm-min, $screen-sm-max) {
            font-size: 18px;
        }

        i {
            color: $footerLight;
            top: 2px;
        }

        span {
            display: inline-block;
            padding: 0 7px 0 10px;
            font-weight: lighter;
            font-style: italic;

            + span {
                border-left: 1px solid $lead;
            }
        }
    }

    .company-link {
        margin-top: 10px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: right;
        a {
            color: $lead;

            &:hover {
                text-decoration: none;
                color: $lead;
            }
        }
    }

    #copyright {
        font-size: 10px;
        color: $copyrightColor;
        margin-top: 40px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @include max-screen($screen-xs-max) {
            text-align: center;
        }

        a {
            color: $lead;

            &:hover {
                color: $copyrightColor;
                text-decoration: none;
            }
        }

        .wizjo {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 10px 0 40px;
            @include min-screen($screen-sm-min) {
                padding: 0;
                text-align: right;
                justify-content: flex-end;

            }

            a {
                color: $lead;
                margin-left: 5px;
            }

            img {
                vertical-align: top;
                width: 25px;
            }
        }
    }

    .scroll-top{
        position: absolute;
        bottom: 0;
        transform: translate(-50%,50%);
        left: 50%;
        background-color: $lead;
        color: #fff;
        width: 50px;
        height: 50px;
        z-index: 3;
        border-radius: 50%;
        text-align: center;
        font-size: 24px;

        i{
            transform: translateY(-3px);
            display: inline-block;
        }
    }
}

/*
 * PAGES
 */
//start page
#startCategories {

    @include min-screen($screen-md-min) {
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        min-height: 68vh;
    }

/*    @include max-screen($screen-sm-max) {
        display: none;
        height: 0;
    }
*/
    #logoStart {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;

        @include min-screen($screen-md-min) {
            margin-top: 25px;
        }

        @include max-screen-height(650px) {
            height: 80px;
            margin-top: 15px;
        }
    }

    #goDown {
        #page-main-categories & {
            display: none;
        }
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    }

    .left {
        display: flex;
        justify-content: space-between;
        left: 0;
        top: 5%;

        .loan {
            margin-bottom: 20px;
        }
    }

    .right {
        position: absolute;
        right: 0;
        top: 5%;
        font-weight: bold;
        font-size: 16px;
        color: $lead;

        span {
            display: block;
            white-space: nowrap;
            padding-right: 70px;
            line-height: 25px;
            position: relative;
            top: 7px;
        }

        .icon {
            float: right;
            background-color: $lead;
            padding: 10px;
            i {
                font-size: 30px;
                padding: 5px;
                color: #000;
                opacity: 0.5;
            }
        }
    }

    @include max-screen($screen-sm-max) {
        #logoStart,
        #goDown,
        .left,
        .right {
            display: none;
        }
    }

    .categories {
        @include min-screen($screen-md-min) {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
        }


        .box {
            @include min-screen($screen-md-min) {
                float: left;
                width: 20%;
                padding-top: 52vh;
            }
            @media (min-width: $screen-md-min) and (max-height: 700px) {
                padding-top: 54vh;
            }

            @include max-screen($screen-sm-max) {
                padding: 1em 0;
            }

            display: block;
            height: 100%;
            position: relative;
            font-size: 18px;
            color: $lead;
            text-transform: uppercase;
            text-decoration: none;
            text-align: center;
            font-weight: bold;
            line-height: 20px;

            span {
                @include min-screen($screen-md-min) {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 16%;
                }

                @media (min-width: $screen-md-min) and (max-height: 700px) {
                    top: 8%;
                }

                @include max-screen($screen-sm-max) {
                    margin-bottom: 1em;
                }

                display: block;
                width: 60px;
                height: 60px;
                background-color: #fff;
                border-radius: 100%;
                margin: 0 auto;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url('../images/icons.png');
                @include transition(all $startAnimationTime);
            }

            &:nth-child(1n+2) {
                @include min-screen($screen-md-min) {
                    border-left: 1px solid $startBorder;
                }

                @include max-screen($screen-sm-max) {
                    border-bottom: 1px solid $startBorder;
                }

            }

            &:before {
                @include max-screen($screen-sm-max) {
                    background-position: top;
                }
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                content: ' ';
                z-index: -1;
                @include filter(grayscale(100%));
                @include transition(all $startAnimationTime);
            }

            &:hover {
                &:before {
                    @include filter(grayscale(0));
                }

                span {
                    background-color: $lead;
                }

                &.opony-terenowe {
                    span {
                        background-position: $iconPositionTerenowe -60px;
                    }
                }
                &.opony-lesne {
                    span {
                        background-position: $iconPositionLesne -60px;
                    }
                }
                &.opony-rolnicze {
                    span {
                        background-position: $iconPositionRolnicze -60px;
                    }
                }
                &.opony-przemyslowe {
                    span {
                        background-position: $iconPositionPrzemyslowe -60px;
                    }
                }
                &.opony-ciezarowe {
                    span {
                        background-position: $iconPositionCiezarowe -60px;
                    }
                }
            }

            &.opony-terenowe {
                &:before {
                    background-image: url('../images/start/terenowe.jpg');
                }

                span {
                    background-position: $iconPositionTerenowe 0;
                }
            }
            &.opony-lesne {
                &:before {
                    background-image: url('../images/start/lesne.jpg');
                }

                span {
                    background-position: $iconPositionLesne 0;
                }
            }
            &.opony-rolnicze {
                &:before {
                    background-image: url('../images/start/rolnicze.jpg');
                }

                span {
                    background-position: $iconPositionRolnicze 0;
                }
            }
            &.opony-przemyslowe {
                &:before {
                    background-image: url('../images/start/przemyslowe.jpg');
                }

                span {
                    background-position: $iconPositionPrzemyslowe 0;
                }
            }
            &.opony-ciezarowe {
                &:before {
                    background-image: url('../images/start/ciezarowe.jpg');
                }

                span {
                    background-position: $iconPositionCiezarowe 0;
                }
            }

        }
    }
}
section {
    &#promo {
        background-color: #fff;
        padding-bottom: 150px;
    }
    &#home {
        @extend section.content;
        background-image: url('../images/start.jpg');

        @include max-screen($screen-sm-max) {
            background-position: left top;
        }

        padding-top: 120px;
        text-align: justify;
        font-size: 14px;
        font-weight: lighter;

        strong {
            font-weight: 500;
        }

        p {
            line-height: 22px;
        }

        h2 {
            color: $headerText;
            font-size: 17px;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-weight: lighter;
            margin-bottom: 45px;
        }

        .row {
        }

        .news-item-wrap{
            max-width: 226px;
        }

        .news-item{
            display: block;
            position: relative;
            margin: 0 0 30px;

            &:before{
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                opacity: .7;
                background-color: #000;
                transition: all .2s ease-in-out;
            }

            img{
                max-width: 100%;
                height: auto;
            }

            &:hover{
                &:before{
                    background-color: $lead;
                }
            }
        }

        .news-headline {
            text-align: left;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 3;
            color: #fff;
            font-size: 14px;
            padding: 20px 15px;
            font-weight: bold;

            @media (min-width: $screen-xs-min){
                font-size: 16px;
            }

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }

            time {
                display: block;
                font-weight: bold;
                font-size: 12px;
                margin-bottom: 10px;
            }
        }
    }
}

//news
section#news {
    time {
        display: block;
        color: $lead;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 0.5em;
    }

    aside {
        h3 {
            text-transform: uppercase;
            letter-spacing: 0.2em;
            font-size: 18px;
            color: $asideHeader;
            font-weight: lighter;
            margin-bottom: 30px;
            margin-top: 0;
        }

        .news-side-item {
            margin-bottom: 25px;

            a {
                display: block;
                color: $linkAlt;
                font-size: 16px;

                &:hover {
                    color: $linkAlt;
                    text-decoration: none;
                }

                .more {
                    display: block;
                    position: relative;
                    background-image: url('../images/more.png');
                    background-repeat: no-repeat;
                    background-position: left center;
                    height: 18px;
                    z-index: 1;
                    margin: 10px 0;

                    &:after {
                        position: absolute;
                        display: block;
                        height: 1px;
                        top: 9px;
                        left: 18px;
                        right: 0;
                        background-color: $asideLine;
                        content: ' ';
                        z-index: 0;
                    }
                }
            }
        }
        .pagination {
            display: block;
            margin: 0;
            padding: 0;
            border-radius: 0;

            ul {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    float: left;
                    margin: 0 15px 15px 0;

                    a {
                        text-align: center;
                        line-height: 21px;
                        display: block;
                        width: 25px;
                        height: 25px;
                        font-weight: bold;
                        font-size: 16px;
                        border: 2px solid $lead;
                        color: $text;
                        margin: 0;
                        padding: 0;
                        background-image: none;

                        &.active,
                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }

                        &.active,
                        &:hover {
                            color: #fff;
                            background-color: $lead;
                        }
                    }
                }
            }
        }
    }
    article {
        padding-left: 30px;
        padding-bottom: 1em;
        border-left: 1px solid $asideLine;
        h1 {
            font-size: 36px;
            font-weight: lighter;
            margin-top: 0;
        }

        font-size: 14px;
        font-weight: lighter;
        text-align: justify;
    }

    .slider {
        margin: 1em 0;
        img {
            border-left: 70px solid $lead;
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0;
        }
    }
}

//tires - index
section#tireIndex {
    aside {
        padding-left: 30px;
        background-image: url('../images/index-size.png');
        background-position: left center;
        background-repeat: no-repeat;
        min-height: 150px;
        @include min-screen($screen-md-min) {
            margin-top: -36px;
        }

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                margin: 0;
                padding: 0;
                text-align: center;
                font-size: 14px;
                font-weight: bold;
                margin: 5px 0;

                @include max-screen($screen-xs-max) {
                    margin: 10px 0;
                    padding: 5px;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid $asideLine;
                }

                a {
                    color: $lead;
                    display: block;

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &:hover {
                        color: $text;
                    }
                }
            }
        }
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 40px;

        tr {
            th {
                color: $textAlt;
                font-size: 20px;
                //padding: 10px;
                &:first-child {
                    text-align: right;
                    padding-right: 15px;
                }

                &:last-child {
                    padding-left: 15px;
                }
            }
            td {
                &.size-big {
                    vertical-align: top;
                    font-size: 60px;
                    font-weight: bold;
                    text-align: right;
                    padding-right: 15px;
                    border-right: 2px solid $asideLine;
                    color: $textAlt;
                }

                &.size-list {
                    padding-left: 15px;
                    vertical-align: middle;
                    a {
                        font-weight: bold;
                        color: $lead;
                        margin: 5px 30px 5px 0;
                        @include min-screen($screen-md-min) {
                            display: inline-block;
                            width: 20%;
                        }

                        @include max-screen($screen-sm-max) {
                            display: inline-block;
                            padding: 5px 0;
                            width: 40%;
                        }

                        @include max-screen($screen-xs-max) {
                            width: 100%;
                        }

                        box-sizing: border-box;
                        vertical-align: top;

                        &:hover {
                            color: $text;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    h1 {
        font-weight: lighter;
        font-size: 36px;
        margin: 0;
        text-align: center;
        color: $textAlt;
    }
}

//tires - list
section#tires {
    .product-list-item {
        margin: 1px;
        @include min-screen(610px) {
            float: left;
            width: calc(50% - 2px);
        }

        @include min-screen($screen-md-min) {
            width: calc(33% - 2px);
        }

        @include min-screen($screen-lg-min) {
            width: calc(25% - 2px);
        }
    }
}

//tires - single
section#tire {
    .product-image {
        background-color: #fff;
        padding: 35px;
        margin-bottom: 20px;

        img {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }
    }

    .product-extras {
        > div {
            @include min-screen($screen-md-min) {
                float: left;
                width: 50%;
            }

            @include max-screen($screen-md-max) {
                padding-left: 10px;
                padding-right: 10px;
            }

            text-align: left;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 1em;
            height: 60px;
            padding-top: 15px;

            &.type {
                background-position: left center;
                background-repeat: no-repeat;
                padding-left: 65px;

                @each $type in terenowe, ciezarowe, rolnicze, lesne, przemyslowe {
                    &-#{$type} {
                        background-image: url('../images/type-#{$type}.png');
                    }
                }
            }

            &.warranty {
                span {
                    float: left;
                    height: 100%;
                    color: $lead;
                    font-size: 2.2em;
                    line-height: 1em;
                    font-weight: bold;
                    margin-right: 3px;
                }
            }
        }
    }
    h1 {
        font-size: 36px;
        font-weight: lighter;
        margin-top: 0;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .product-attributes {
        width: 100%;
        font-size: 14px;

        tr {
            border-bottom: 1px solid $productBorder;

            td {
                width: 50%;
                padding: 3px 0;

                &:first-child {
                    font-weight: bolder;
                }
            }
        }
    }

    .product-benefits {
        img {
            border-radius: 100%;
            border: 3px solid #fff;
            margin-right: 15px;
            margin-bottom: 15px;
            float:left;
            width: 65px;
            height: 65px;

            &:hover {
                border-color: $lead;
            }
        }
    }

    .product-rating {
        color: $lead;
        font-size: 16px;
    }

    .add-to-cart {
        margin: 20px 0;
        border: 1px solid $productCartBorder;
        background-color: #fff;
        line-height: 1.3;

        @media (min-width: $screen-sm-min){
            display: inline-block;
        }

        .cart{

            @media (min-width: $screen-sm-min){
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                margin: 0 -8px;
            }

            > *{
                &:not(:last-child){
                    @media (max-width: $screen-xs-max){
                        margin: 0 0 20px;
                    }
                }
                @media (min-width: $screen-sm-min){
                    padding: 0 8px;
                    margin: 0;
                }
            }
        }

        .price-wrap{
            .promo-label{
                color: $lead;
                text-transform: uppercase;
                font-weight: bold;
                display: block;
                margin: 0 0 20px;
            }
            .regular-price{
                display: block;
                color: #4c4c4c;
                font-size: 18px;
                text-decoration: line-through;
                margin: 0 0 5px;
                font-weight: 500;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                background-color: $productTabDisabled;
                display: block;
                font-size: 14px;
                font-weight: bold;
                text-transform: uppercase;

                &.active {
                    background-color: #fff;

                    a {
                        color: $lead;

                        img {
                            opacity: 1;
                            @include filter(grayscale(0));
                        }
                    }
                }

                a {
                    display: block;
                    color: #000;
                    text-decoration: none;

                    img {
                        float: left;
                        margin-top: 0.5em;
                        margin-right: 5px;
                        opacity: 0.5;
                        @include filter(grayscale(100%));
                    }
                }
            }
        }
        > div {
            > div {
                display: none;

                &.active {
                    display: block;
                }
            }
        }

        form {
            padding: 30px 20px;

            .price {
                font-size: 30px;
                color: $lead;
                font-weight: normal;
                vertical-align: middle;
            }

            .quantity {
                vertical-align: middle;
                display: inline-flex;
                align-items: center;
                input {
                    border-radius: 0;
                    width: 2.4em;
                    font-size: 18px;
                    font-weight: bolder;
                    height: 35px;
                    line-height: 1em;
                    margin-top: 5px;
                    text-align: center;
                    padding: 6px 0;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    &:focus {
                        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($lead, 0.6);
                        border-color: $lead;
                    }
                }
                .quantity-btns{
                    .quantity-btn{
                        color: $lead;
                        background-color: #fff;
                        display: block;
                        line-height: 1;
                        cursor: pointer;
                        padding: 6px 6px;

                        &:first-child{
                            padding-bottom: 0;
                        }

                        &:last-child{
                            padding-bottom: 0;
                        }

                        span{
                            transition: all .2s ease-out;
                        }

                        &:hover{
                            span{
                                color: $textAlt;
                            }
                        }
                    }
                }
            }


            button {
                border: 0;
                background-color: $lead;
                color: #fff;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                line-height: 25px;
                padding: 5px 10px;

                i {
                    top: 3px;
                    font-weight: normal;
                    font-size: 16px;
                    margin-right: 5px;
                }
            }
        }
    }
    .express-delivery{
        margin-bottom: 20px;
    }


    .product-desc {
        font-size: 14px;
        font-weight: lighter;
    }
}
/*
 * GLOBAL
 */
.promo-container .slick-track{
    padding-top: 20px;
}
//product list
.product-list-item {
    padding: 30px;
    text-align: center;
    position: relative;
    cursor: pointer;
    background-color: #fff;
    z-index: 1;
    color: $text;
    text-decoration: none;
    display: block;
    margin-bottom: 1em;

    span {
        display: block;
    }

    .badge{
        position: absolute;
        border-radius: 0;
        font-size: 14px;
        font-weight: bold;
        left: 50%;
        top: 0;
        transform: translate(-50%,-50%);
        background-color: $lead;
        color: #fff;
        padding: 12px 30px;
        height: auto;
        z-index: 2;
        text-transform: uppercase;
        opacity: 0;
        transition: all .3s ease-out;
    }

    .product-name,
    .price {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .product-name {
        width: 60%;
        height: 3.5em*1.5;
    }
    .price {
        display: inline-block;
        font-size: 0;

        span{
            display: inline-block;
        }

        del{
            display: block;
            color: #2b2b2b;
            max-height: 0;
            overflow: hidden;
            transition: all .3s ease-out;
            font-size: 14px;
            span{
                text-decoration: line-through;
            }
        }

        ins{
            color: #fff;
            display: inline-block;
            width: auto;
            padding: 5px 0 5px 10px;
            text-transform: uppercase;
            background-color: $lead;
            font-size: 14px;
        }

        small{
            display: inline-block;
            color: #fff;
            padding: 5px 10px 5px 5px;
            background-color: $lead;
            font-size: 14px;
        }
    }

    .image {
        height: 200px;
        margin-bottom: 20px;
        position: relative;
        display: inline-block;

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: $listItemHover;
            opacity: 0;
            //display: none;
            content: ' ';
            z-index: 0;
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &:before {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        opacity: 0;
        background-color: $listItemHover;
    }

    &:hover,
    &:focus {
        color: $text;
        text-decoration: none;

        &:before {
            opacity: 1;
        }

        .badge{
            opacity: 1;
        }

        .image {
            &:after {
                opacity: 1;
            }
        }

        .icon {
            span {
                background-color: $lead;
            }
        }
    }

    .icon {
        position: relative;
        width: 100%;
        height: 60px;
        text-align: center;
        margin: 20px auto;

        &:before {
            position: absolute;
            content: ' ';
            top: 30px;
            left: 0;
            right: 0;
            width: 100%;
            height: 1px;
            z-index: -1;
            background-color: $typeIconLine;
        }

        span {
            background-position: center;
            background-repeat: no-repeat;
            background-image: url('../images/icons.png');
            display: inline-block;
            width: 60px;
            height: 60px;
            background-color: $iconBackground;
            border-radius: 100%;
        }
    }

    &:not(.promo-product){
        .woocommerce-Price-amount{
            font-size: 14px;
            background-color: $lead;
            padding: 5px 0 5px 10px;
            color: #fff;
        }
    }

    &.promo-product{
        .price{
            del{
                max-height: 0;
            }
        }
        &:hover, &:focus{
            .price{
                del{
                    max-height: 50px;
                    padding: 8px;
                }
            }
        }
    }

    &.type- {
        &terenowe {
            .icon {
                span {
                    background-position: $iconPositionTerenowe -60px;
                }
            }
        }

        &ciezarowe {
             .icon {
                 span {
                     background-position: $iconPositionCiezarowe -60px;
                 }
             }
        }

        &lesne {
            .icon {
                span {
                    background-position: $iconPositionLesne -60px;
                }
            }
        }

        &przemyslowe {
            .icon {
                span {
                    background-position: $iconPositionPrzemyslowe -60px;
                }
            }
        }

        &rolnicze {
            .icon {
                span {
                    background-position: $iconPositionRolnicze -60px;
                }
            }
        }

        &unknown {
            .icon {
                span {
                    background-position: $iconPositionUnknown -60px;
                }
            }
        }
    }

    .image:after,
    .icon span,
    &:before {
        @include transition(all 0.5s);
    }
}

//global
.marquee {
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    height: 180px;

    .marquee-cont {
        position: absolute;
        white-space: nowrap;
        left: 0;
        margin: 70px 0;

        > div {
            width: auto;
            padding: 0 30px;
            display: inline;

            img {
                max-height: 40px;
            }
        }
    }
}
.goTop {
    //display: inline-block;
    display: none;
    position: fixed;
    bottom: 3em;
    right: 3em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    color: #fff;
    text-align: center;
    background-color: $lead;
    border-radius: 0.5em;

    &:hover,
    &:focus {
        color: #fff;
        text-decoration: none;
    }
}
.nopad- {
    &l {
        padding-left: 0;
    }

    &r {
        padding-right: 0;
    }
}
.breadcrumbs {
    ul {
        list-style-type: none;
        margin: 0 0 65px 0;
        padding: 0;
        text-align: center;

        li {
            display: inline-block;
            font-size: 12px;
            position: relative;

            a {
                color: $text;

                &:hover {
                    color: $lead;
                    text-decoration: none;
                }
            }

            + li {
                &:before {
                    color: $lead;
                    content: '/';
                    padding: 0 10px;
                }
            }
        }
    }
}
.tooltip {
    &.bottom {
        margin-top: -5px;
    }

    &.in {
        opacity: 1;
    }

    &.bottom {
        .tooltip-arrow {
            border-bottom-color: #fff;
            border-width: 0 24px 11px;
            margin-left: -24px;
        }
    }

    .tooltip-inner {
        background-color: #fff;
        box-shadow: 0 0 5px rgba($text, 0.5);
        color: #000;
        border-radius: 0;
        font-weight: bolder;
        font-size: 12px;
        padding: 10px 40px;
        text-transform: uppercase;
    }
}
img.alignnone {
    max-width: 100%;
    height: auto;
    //display: block;
}
//shortcodes
.promo-container {
    h2 {
        text-align: center;
        color: $headerText;
        font-size: 17px;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        font-weight: lighter;
        margin-bottom: 30px;
    }
}

//outline
a {
    &:focus {
        outline: none;
    }
}

.row--flex{
    display: flex;
    flex-wrap: wrap;
}
