.extra-categories{

    @media (min-width: $screen-sm-min){
        display: flex;
    }

    .extra-category{
        position: relative;
        display: block;
        overflow: hidden;

        @media (min-width: $screen-sm-min){
            width: 50%;
        }

        .extra-category__img{
            transition: all .3s ease-out;
        }

        .extra-category__title{
            position: absolute;
            z-index: 2;
            bottom: 6%;
            left: 10%;
            color: #fff;
            letter-spacing: .2em;
            text-transform: uppercase;
            font-size: 5vw;

            @media (min-width: $screen-xs-min){
                font-size: 4vw;
            }

            @media (min-width: $screen-sm-min) {
                font-size: 3vw;
            }

            @media (min-width: $screen-md-min){
                font-size: 30px;
            }
        }

        &:hover{
            .extra-category__img{
                transform: scale(1.05);
            }
        }
    }
}
