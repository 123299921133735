.contact-wrap{
    @media (min-width: $screen-md-min){
        padding: 60px 0;
        display: flex;
    }

    .contact-content{
        background-color: #333333;
        color: #fff;
        padding: 40px 30px;
        font-size: 16px;

        @media (min-width: $screen-xs-min){
            font-size: 18px;
        }

        @media (min-width: $screen-md-min){
            width: 33.33%;
        }

        .contact-title{
            margin: 0 0 35px;
        }

        a{
            color: $lead;
            font-weight: bold;
        }

        p{
            margin-bottom: 25px;
        }
    }

    .contact-map{
        @media (min-width: $screen-md-min){
            width: 66.66%;
        }

        iframe{
            width: 100%;
            height: 100%;
            min-height: 400px;
        }
    }
}
