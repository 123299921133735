.stats{
    background-image: url(../images/mesh.png);
    padding: 50px 0;
    overflow: hidden;
    .stat{
        position: relative;
        margin: 0 0 45px;
        .stat__bg{
            position: relative;
            z-index: 2;
            img{
                margin: 0 auto;
            }
        }
        .stat-value{

        }

        .stat__text{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            text-align: center;
            z-index: 3;
        }

        .stat__value{
            color: $lead;
            display: block;
            font-size: 32px;
            font-weight: bold;

            @media (min-width: $screen-xs-min){
                font-size: 36px;
            }

            @media (min-width: $screen-sm-min){
                font-size: 30px;
            }

            @media (min-width: $screen-md-min){
                font-size: 46px;
            }
        }

        .stat__caption{
            color: #fff;
            line-height: 1.2;
            @media (min-width: $screen-xs-min){
                font-size: 12px;
            }
            @media (min-width: $screen-sm-min){
                font-size: 16px;
            }
            @media (min-width: $screen-md-min){
                font-size: 18px;
            }
        }

        .stat__shadow{
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-50%,50%);
            mix-blend-mode: multiply;
            z-index: 1;
        }
    }
    .odometer-formatting-mark{
        display: none;
    }

    .stats__heading{
        text-align: center;
        padding: 10px 0 0;
    }

    .stats__heading-title{
        text-transform: uppercase;
        color: $lead;
        font-size: 16px;
        margin: 0 0 20px;
        @media (min-width: $screen-xs-min){
            font-size: 18px;
        }
    }

    .stats__heading-subtitle{
        text-transform: uppercase;
        color: #fff;
        font-size: 24px;
        margin: 0 0 20px;
        letter-spacing: .2em;

        @media (min-width: $screen-xs-min){
            font-size: 30px;
        }

        @media (min-width: $screen-sm-min){
            font-size: 50px;
        }
    }
}
