.opinions{
    background-image: url(../images/redbg.jpg);
    padding: 70px 0 40px;
    .opinions__heading-title{
        text-align: center;
        color: #fff;
        font-size: 30px;
        text-transform: uppercase;
        letter-spacing: .2em;
        margin: 0 0 50px;
    }

    .opinion{
        background-color: rgba(0, 0, 0, .2);
        color: #fff;
        padding: 70px 30px;
        margin-bottom: 75px;
        position: relative;
    }

    .opinion__footer{
        background-color: #000;
        text-align: center;transform: translateY(50%);
        position: absolute;
        bottom: 0;
        width: calc(100% - 60px);
        left: 30px;
        padding: 15px;
        font-weight: 500;

        &:before{
            content: "";
            position: absolute;
            bottom: calc(100% - 1px);
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 10px solid black;
        }

        .opinion__author{
            font-style: italic;
            display: block;
            margin: 0 0 5px;
        }

        .opinion__fate{
            font-size: 14px;
        }
    }
}
