//button
.woocommerce {
    #respond input#submit,
    a.button,
    button.button,
    input.button {

        &,
        &:disabled[disabled],
        &.alt {
            color: #fff;
            background-color: $lead;
            display: inline-block;
            width: auto;
            padding: 5px 10px;
            text-transform: uppercase;
            border-radius: 0;
            font-size: 14px;

            &:hover {
                background-color: $lead;
                color: #fff;
            }
        }
    }
}

//image
.woocommerce-placeholder {
    padding: 10px;
    background-color: #fff;
}

//cart table
.cart_totals {
    table {
        td {
            padding: 3px 10px;
        }

        margin-bottom: 10px;
    }
}

.woocommerce table.shop_table,
.woocommerce-checkout #payment,
.woocommerce-checkout #payment div.payment_box {
    border-radius: 0;
}

//form
#billing_country_field {
    display: none;
}

.woocommerce {
    form
    .form-row {
        input.input-text,
        textarea {
            border: 1px solid $text;
            padding: 10px 15px;
            background-color: transparent;
        }

        &.woocommerce-validated {
            .select2-container,
            input.input-text,
            select {
                border-color: $text;
            }
        }

        &.woocommerce-invalid {
            .select2-container,
            input.input-text,
            select {
                border-color: $lead;
            }
        }
    }

    .radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"]{
        position: static;
    }
}
